import React, { useState } from "react";
import "./Main.css";
import Header from "../components/Header";
import About from "./About";
import Chat from "./Chat";
import Projects from "./Projects";
import Other from "./Other";

const Main = () => {
  const [activeSection, setActiveSection] = useState('About');

  // Content for each section
  const sectionContent = {
    About: About,
    Projects: Projects,
    Other: Other,
    // Chat: Chat,  
  };

  const CurrentContent = sectionContent[activeSection as keyof typeof sectionContent];

  return (
    <>
      <Header 
        activeItem={activeSection} 
        onMenuChange={setActiveSection}
      />
       <CurrentContent />
    </>
  );
};

export default Main;
