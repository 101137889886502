import React from "react";
import './Main.css';
import Project from "../components/Project";
import profilePicture from '../assets/images/profile.jpg';
import ardaImage from '../assets/images/arda.jpeg';
import topMarketImage from '../assets/images/top_market.png';
const Projects = () => {
  const projects = [
    {
      title: "Arda: Health-drone delivery for the Gambia",
      image: ardaImage,
      authors: [
        { name: "Jean Ghislain Billa", imageUrl: profilePicture },
        { name: "Jan Kryca" },
      ],
      annotations: {},
      detailsPath: "/projects/arda"
    },

    {
      title: "Top Market: e-commerce platform for West Africa",
      image: topMarketImage,
      authors: [
        { name: "Jean Ghislain Billa", imageUrl: profilePicture },
      ],
      annotations: {},
      detailsPath: "/projects/top-market"
    },

    {
      title: "[Hackathon] Exploring COMPAS",
      authors: [
        { name: "Jean Ghislain Billa", imageUrl: profilePicture },
        { name: "Vivian Chinoda" },
      ],
      annotations: {},
      codeLink: "https://github.com/jgbilla/btg_hackathon",
    },

    {
      title: "[Hackathon] Edpod: Learn anywhere with other people",
      authors: [
        { name: "Jean Ghislain Billa", imageUrl: profilePicture },
        { name: "Vivian Chinoda" },
        { name: "Qudus Shittu" },
      ],
      annotations: {},
      codeLink: "https://github.com/jgbilla/EdPod",
    },

    {
      title: "[Hackathon] DaNews: Compare the sentiment of different news sources",
      authors: [
        { name: "Jean Ghislain Billa", imageUrl: profilePicture },
        { name: "Abdullah Negm" },
      ],
      annotations: {},
      codeLink: "https://github.com/jgbilla/EdPod",
    },

    {
      title: "Globowl: Discover food from around the world",
      authors: [
        { name: "Jean Ghislain Billa", imageUrl: profilePicture },
        { name: "Wilhem Hector" },
        { name: "Vivian Clarissah Chinoda" },
      ],
      annotations: {},
      codeLink: "https://github.com/weblab-class/Visionnaire19-wilhem-hector-vchinoda",
    },
  ]
    return (
      <>
         <div className="main-container">
        <div className="content-column">
      
      <p>I worked on a variety of projects, ranging from drone development to web development.</p>
      {projects.map((project, index) => (
        <Project
          key={index}
          {...project}
        />
      ))}
        </div>
        </div>
      </>
    );
  };
  
  export default Projects;