import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import './ProjectDetails.css';
import { Container, Typography, Box } from '@mui/material';
import rehypeRaw from 'rehype-raw';

const ProjectDetails = () => {
  const { projectId } = useParams();
  console.log(projectId);
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(`/content/projects/${projectId}.md`);
        const text = await response.text();
        setContent(text);
      } catch (error) {
        console.error('Error loading project details:', error);
      }
    };

    fetchContent();
  }, [projectId]);

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Box sx={{ maxWidth: '800px', margin: '0 auto', textAlign: 'left' }}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
      </Box>
    </Container>
  );
};

export default ProjectDetails;