import React from "react";
import './Main.css';
import Project from "../components/Project";
import asaImage from '../assets/images/ASA.png';
import lbImage from '../assets/images/lb.png';
import btgImage from '../assets/images/btg.jpeg';

const Other = () => {

  const projects = [
    {
      title: "Leadership Brainery",
      image: lbImage,
      authors: [
      ],
      annotations: {},
      detailsPath: "/projects/leadership-brainery",
      
    },

    {
      title: "MIT African Students Association",
      image: asaImage,
      authors: [
      ],
      annotations: {},
      detailsPath: "/projects/mit-africa"
    },

    {
      title: "MIT Black Theater Guild",
      image: btgImage,
      authors: [
      ],
      annotations: {},
      detailsPath: "/projects/btg"
    },
  ]
    return (
      <>
         <div className="main-container">
        <div className="content-column">
      
      <p>I was fortunate enough to be part of multiple organizations so far.</p>
      {projects.map((project, index) => (
        <Project
          key={index}
          {...project}
        />
      ))}
        </div>
        </div>
      </>
    );
  };
  
  export default Other;