import React from "react";
import './About.css';
import './Main.css';
import profilePicture from '../assets/images/profile.jpg';
import Project from "../components/Project";
import sptImage from '../assets/images/spt.png';
import mindMapperImage from '../assets/images/mindmapper.png';
import digitalHistoryImage from '../assets/images/dig_hist.jpg';
import storyGenerationImage from '../assets/images/story_gen.png';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const projects = [
    {
      title: "Supervisory Prompt Training",
      image: sptImage,
      authors: [
        { name: "Jean Ghislain Billa", imageUrl: profilePicture },
        { name: "Min Oh" },
        { name: "Liang Du" }
      ],
      annotations: {},
      paperLink: "https://arxiv.org/abs/2403.18051"
    },

    {
      title: "MindMapper",
      image: mindMapperImage,
      authors: [
        { name: "Jean Ghislain Billa", imageUrl: profilePicture },
        { name: "Valdemar Danry" },
        { name: "Pattie Maes" }
      ],
      annotations: {},
      detailsPath: "/projects/mindmapper"
    },

    {
      title: "Digital History Project",
      image: digitalHistoryImage,
      authors: [
        { name: "Kurt Fendt (PI)" },
        { name: "Jan Vondráček (co-PI)" },
        { name: "Jean Ghislain Billa", imageUrl: profilePicture },
        { name: "Max Frischknecht" },
        { name: "Trudy Painter" },
        { name: "Amanda Tong" },
        { name: "Lucie Kalabisová" },
        { name: "Miranda Patrizia Martinez Elton" },
      ],
      annotations: {},
      codeLink: "https://github.com/dighist/GPT",
      detailsPath: "/projects/digital-history"
    },

    {
      title: "Automatic Story Generation",
      image: storyGenerationImage,
      authors: [
        { name: "Jean Ghislain Billa", imageUrl: profilePicture },
        { name: "Gregor Jouet" },
        { name: "Clement Duhart" }
      ],
      annotations: {},
      detailsPath: "/projects/story-generation"
    },

  ];

const About = () => {
  return (
    <>
      <div className="main-container">
        <div className="content-column">
        <p style={{ lineHeight: "1.8" }}> I am Jean Ghislain Billa, a senior at MIT studying Artificial Intelligence and Decision Making. I was born and raised in <a href="https://en.wikipedia.org/wiki/Burkina_Faso" style={{ color: "#BA3B46" }}>Burkina Faso</a>. I like to think of myself as a:</p>
        <h3 style={{ color: "#BA3B46" }}>Researcher</h3>
          <p style={{ lineHeight: "1.8" }}>My interest in AI began at age nine, inspired by JARVIS in Iron Man, which demonstrated the idea of a human-AI collaboration that could augment human potential exponentially. I pursued projects to build this vision but repeatedly encountered a critical barrier: <strong>the lack of trust in AI</strong>. AI models often operate as black boxes, limiting understanding and control, which hinders collaboration and fuels fears of AI unpredictability and human replacement. </p>
          <p style={{ lineHeight: "1.8" }}>To address this challenge, my research interest focuses on <strong>understanding opaque models while building interpretable AI systems.</strong></p>

          <h3 style={{ color: "#BA3B46" }}>Builder</h3>
          <p style={{ lineHeight: "1.8" }}> I enjoy building products that address a real-world problem, or that represent a significant challenge. See a list of projects I have worked on in the Projects section. </p>

          <hr className="light-divider" />
          <h2> Main Publications and Projects</h2>
          {projects.map((project, index) => (
        <Project
          key={index}
          {...project}
        />
      ))}
        </div>
        
        <div className="sidebar-column">
            <img src={profilePicture} alt="Profile" className="profile-image" />
            <h2>Jean Ghislain Billa</h2>
            <p>Massachusetts Institute of Technology Class of 2025</p>
            <p>Artificial Intelligence and Decision Making, B.S.</p>
            <hr className="light-divider" />
            <p style={{ color: "#BA3B46" }}>jbilla@mit.edu</p>
            <div style={{ display: 'flex', gap: '15px', marginBottom: '10px' }}>
                <a href="https://www.linkedin.com/in/jeanghislainbilla/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={24} style={{ color: "#BA3B46" }} />
                </a>
                <a href="https://github.com/jgbilla" target="_blank" rel="noopener noreferrer">
                    <FaGithub size={24} style={{ color: "#BA3B46" }} />
                </a>
            </div>

            <a href="https://drive.google.com/file/d/1dLLRhtbu_Xf7bnm_1CkK26KbZ7K2vNzd/view?usp=sharing" style={{ color: "#BA3B46" }}>Curriculum Vitae</a>
        </div>
      </div>
    </>
  );
};

export default About;