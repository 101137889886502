import React from 'react';
import './Project.css';
import { Link } from 'react-router-dom'; 

interface Author {
  name: string;
  imageUrl?: string;
}

interface ProjectProps {
  title: string;
  authors: Author[];
  image?: string;
  codeLink?: string;
  paperLink?: string;
  detailsPath?: string;
  annotations: {
    conference?: string;
    year?: string;
    awards?: string[];
  };
}

const Project: React.FC<ProjectProps> = ({
  title,
  authors,
  image,
  codeLink,
  paperLink,
  detailsPath,
  annotations
}) => {
  return (
    <div className="project-card">
      {image && <img src={image} alt={title} className="project-image" />}
      
      <div className="project-content">
        <h4 className="project-title">{title}</h4>
        
        <div className="authors-container">
          {authors.map((author, index) => (
            <div key={index} className="author">
              {author.imageUrl && (
                <img src={author.imageUrl} alt={author.name} className="author-avatar" />
              )}
              <span>{author.name}</span>
              {index < authors.length - 1 && <span>, </span>}
            </div>
          ))}
        </div>

        <div className="annotations">
          {annotations.conference && (
            <span className="conference">{annotations.conference} {annotations.year}</span>
          )}
          {annotations.awards && annotations.awards.map((award, index) => (
            <span key={index} className="award">{award}</span>
          ))}
        </div>

        <div className="project-links">
          {paperLink && (
            <a href={paperLink} className="link">
              Paper
            </a>
          )}
          {codeLink && (
            <a href={codeLink} className="link">
              Code
            </a>
          )}
          {detailsPath && (
          <Link to={detailsPath} className="link">
            Learn More
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Project;